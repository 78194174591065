import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import useLiterals from 'utils/hooks/useLiterals';
import Layout from 'components/Layout';
import { getSectionReference } from 'modules/sections/actions';
import { getReference } from 'modules/contracts/actions';
import { formatDate } from 'utils/formatDate';
import * as animationDataSuccess from 'assets/animations/done.json';
import * as animationDataFailure from 'assets/animations/info.json';
import Button from 'components/UI/Button';
import { ROUTE_PATH } from 'routes';
import { formatCurrency } from 'utils/formatCurrency';
import { useDispatch, useSelector } from 'react-redux';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import { loadingActions } from 'modules/loading';
import LogoImg from 'assets/logos/logo.png';
import './styles.scss';

const PaymentResponse = () => {
  const literals = useLiterals('contractPay');
  const [payment, setPayment] = useState(null);
  const { type, id, referenceId } = useParams();
  const { authenticated } = useSelector((state) => state.session);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const isSection = type === 'sections';
  const dispatch = useDispatch();

  useEffect(() => {
    if (authenticated) {
      dispatch(loadingActions.show());
      const functionGetReference = isSection ? getSectionReference : getReference;
      functionGetReference(id, referenceId)
        .then((response) => {
          setPayment(response);
        }).catch(() => {
          setError(true);
        }).finally(() => {
          dispatch(loadingActions.hide());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  const renderHeader = () => {
    const urlPath = window.location.pathname;
    const isSuccess = urlPath.includes('success');
    const isFailure = urlPath.includes('failure');
    if (isSuccess) {
      return (
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animationDataSuccess.default,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={90}
            width={90}
          />
          <h1 className='mt-0'>{literals.bancomer.successTitle}</h1>
        </div>
      );
    }
    if (isFailure) {
      return (
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <div className='pt-4 pb-3'>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: animationDataFailure.default,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={50}
              width={50}
            />
          </div>
          <h1 className='mt-0'>{literals.bancomer.failureTitle}</h1>
        </div>
      );
    }
    return null;
  };

  const handleGoBack = () => {
    navigate(ROUTE_PATH.HOME);
  };

  const handleCloseError = () => {
    navigate(ROUTE_PATH.HOME, { replace: true });
  };

  return (
    <Layout
      onBack={handleGoBack}
      headerMenu={authenticated}
      bottomMenu={authenticated}
    >
      {!authenticated && (
        <div className='payment-resume-center'>
          <img className='logo' src={LogoImg} alt='Platform logo' />
          {renderHeader()}
          <div>
            {literals.returnToApp}
          </div>
        </div>
      )}
      {payment && (
        <div className='payment-resume'>
          {renderHeader()}
          <h1 className='mb-4 text-primary'>{literals.paymetResume}</h1>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{isSection ? literals.common.section : literals.common.contract}</h2>
            <h3 className='text-gray'>{id}</h3>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{literals.date}</h2>
            <h3 className='text-gray'>{formatDate(payment?.expirationDate, 'DD/MM/YYYY')}</h3>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{literals.paymentMethod2}</h2>
            <h3 className='text-gray font-bold'>{payment?.providerType}</h3>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{literals.status}</h2>
            <h3 className='text-gray font-bold'>{literals.statusValues[payment?.status] || payment?.status}</h3>
          </div>
          <div className='separator my-3' />
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{literals.totalAmount}</h2>
            <h3 className='text-primary font-bold'>{formatCurrency(payment?.amount || 0)}</h3>
          </div>
          <Button
            onClick={() => navigate(ROUTE_PATH.HOME)}
            className='mt-4'
            type='primary'
            text={literals.common.back}
          />
        </div>
      )}
      {error && (
        <ErrorModal
          title={literals.failureLoadingReference}
          description={literals.failureLoadingReferenceDescription}
          onClose={() => handleCloseError()}
        />
      )}
    </Layout>
  );
};

export default PaymentResponse;
