import {
  EVENT_TYPE_BIOMETRIC_RESPONSE,
  EVENT_TYPE_BIOMETRIC_AVAILABILITY,
  EVENT_TYPE_LOCATION_PERMISSIONS,
  EVENT_TYPE_LOCATION_DATA,
  EVENT_TYPE_NOTIFICATIONS_PERMISSIONS,
  EVENT_TYPE_NOTIFICATIONS_TOKEN,
  EVENT_TYPE_CAMERA_PERMISSIONS,
  EVENT_TYPE_STORAGE_RESPONSE,
  EVENT_TYPE_BACK_BUTTON,
  EVENT_TYPE_BIOMETRIC_TYPE,
  EVENT_TYPE_LIBRARY_PERMISSIONS,
} from 'utils/events';
import { sendSentryLog } from './sendSentryLog';

/* eslint-disable */
const urlParams = new URLSearchParams(window.location.search);
const osParam = urlParams.get('os') || window.localStorage.getItem('os') || null;
const OS_ANDROID = 'ANDROID';
const OS_IOS = 'IOS';
const OS_LIST = [OS_ANDROID, OS_IOS];

window.onIOSApp = () => {
  if (osParam && osParam === OS_IOS) {
    return true;
  }
  return false;
}

window.onAndroidApp = () => {
  if (osParam && osParam === OS_ANDROID && typeof window.Android !== 'undefined') {
    return true;
  }
  return false;
}

window.onNativeApp = () => {
  if (osParam && OS_LIST.indexOf(osParam) >= 0) {
    return true;
  }
  return false;
}

/*****************************/
/* NATIVE DEVICE FUNCTIONS */

const userAgent = navigator.userAgent || navigator.vendor || window.opera;

window.onIOSBrowser = () => {
  try {
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

window.onAndroidBrowser = () => {
  try {
    if (/android/i.test(userAgent)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

window.onNativeBrowser = () => {
  if (window.onIOSBrowser() || window.onAndroidBrowser()) {
    return true;
  }
  return false;
}

/*****************************/

window.onIOS = () => {
  if (window.onIOSBrowser() || window.onIOSApp()) {
    return true;
  }
  return false;
}

window.onAndroid = () => {
  if (window.onAndroidBrowser() || window.onAndroidApp()) {
    return true;
  }
  return false;
}

window.onApp = () => {
  return window.onAndroidApp() || window.onIOSApp();
}

/*****************************/
/* BIOMETRICS FUNCTIONS */

/**
 * Saves a value to native storage
 * 
 * @param {String} key storage key
 * @param {JSON} value data to save
 */
window.saveValue = (key, value) => {
  try {
    if (window.onAndroidApp()) {
      Android.saveValue(key, value);
    } else if (window.onIOSApp()) {
      // TODO: iOS does not support saving values with the same key
      window.webkit.messageHandlers.deleteValue.postMessage(key);
      setTimeout(() => {
        window.webkit.messageHandlers.saveValue.postMessage({ key, value });
      }, 1000);
    }
  } catch (error) {
    console.error('saveValue error', { error, key, value });
  }
};

/**
 * Retrieves a value from native storage
 * 
 * @param {String} key storage key
 */
window.getValue = (key) => {
  try {
    if (window.onAndroidApp()) {
      Android.getValue(key);
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.getValue.postMessage(key);
    }
  } catch (error) {
    console.error('getValue error', { error, key });
  }
};

/**
 * Success callback from native storage retrieval
 * 
 * @param {String} key storage key
 * @param {JSON} data data retrieved or null if not found
 */
window.setValue = (data) => {
  const event = new CustomEvent(EVENT_TYPE_STORAGE_RESPONSE, { detail: { data } });
  document.dispatchEvent(event);
};

/**
 * Deletes a value from native storage
 * 
 * @param {String} key storage key
 */
window.deleteValue = (key) => {
  try {
    if (window.onAndroidApp()) {
      Android.deleteValue(key);
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.deleteValue.postMessage(key);
    }
  } catch (error) {
    console.error('deleteValue error', { error, key });
  }
};

window.canUseBiometrics = () => {
  try {
    if (window.onAndroidApp()) {
      return Android.canAuthenticate();
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.canAuthenticate.postMessage('');
    } else {
      window.canAuthenticate(false);
    }
  } catch (error) {
    console.log('canUseBiometrics error', error);
    window.canAuthenticate(false);
  }
};

window.checkBiometricType = () => {
  try {
    if (window.onAndroidApp()) {
      return Android.checkBiometricType();
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.checkBiometricType.postMessage('');
    } else {
      window.biometricType('none');
    }
  } catch (error) {
    console.log('checkBiometricType error', error);
    window.biometricType('none');
  }
};

window.biometricType = (response) => {
  const event = new CustomEvent(EVENT_TYPE_BIOMETRIC_TYPE, { detail: response });
  document.dispatchEvent(event);
};

window.showBiometrics = (key) => {
  try {
    if (window.onAndroidApp()) {
      Android.requestAuthentication(key);
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.requestAuthentication.postMessage(key);
    }
  } catch (error) {
    console.error('showBiometric error', { error, key });
  }
};


window.canAuthenticate = (response) => {
  const event = new CustomEvent(EVENT_TYPE_BIOMETRIC_AVAILABILITY, { detail: { "biometricsAvailable": response } });
  document.dispatchEvent(event);
};

window.authenticationSucceeded = (data) => {
  try {
    let dataObj = null;
    if (typeof data === 'string') {
      dataObj = JSON.parse(data);
    } else if (typeof data === 'object') {
      dataObj = data;
    }
    if (dataObj) {
      const event = new CustomEvent(EVENT_TYPE_BIOMETRIC_RESPONSE, { detail: { success: true, data: { ...dataObj } } });
      document.dispatchEvent(event);
    } else {
      const event = new CustomEvent(EVENT_TYPE_BIOMETRIC_RESPONSE, { detail: { success: false, data: { error: 'Unable to parse local data' } } });
      document.dispatchEvent(event);
    }
  } catch (error) {
    const event = new CustomEvent(EVENT_TYPE_BIOMETRIC_RESPONSE, { detail: { success: false, data: { error: 'Unable to parse local data' } } });
    document.dispatchEvent(event);
    console.log('authenticationSucceeded error', { error, data });
  }
};

window.authenticationError = (data) => {
  try {
    const dataObj = JSON.parse(data);
    const event = new CustomEvent(EVENT_TYPE_BIOMETRIC_RESPONSE, { detail: { success: false, data: { ...dataObj } } });
    document.dispatchEvent(event);
  } catch (error) {
    const event = new CustomEvent(EVENT_TYPE_BIOMETRIC_RESPONSE, { detail: { success: false, data } });
    document.dispatchEvent(event);
    console.error('authenticationError error', data);
    sendSentryLog({ data }, 'Biometric error');
  }
};

window.getLocationPermission = () => {
  try {
    if (window.onAndroidApp()) {
      Android.getLocationPermission();
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.getLocationPermission.postMessage('');
    } else {
      window.setLocationPermission(true);
    }
  } catch (error) {
    console.log('getLocationPermission error', { error });
  }
};

window.setLocationPermission = (permission) => {
  const event = new CustomEvent(EVENT_TYPE_LOCATION_PERMISSIONS, { detail: { permission } });
  document.dispatchEvent(event);
};

window.getCurrentPosition = () => {
  try {
    if (window.onAndroidApp()) {
      Android.getCurrentPosition();
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.getCurrentPosition.postMessage('');
    } else {
      window.setCurrentPosition();
    }
  } catch (error) {
    console.log('getCurrentPosition error', { error });
  }
};

window.setCurrentPosition = (latitude, longitude) => {
  const event = new CustomEvent(EVENT_TYPE_LOCATION_DATA, { detail: { latitude, longitude } });
  document.dispatchEvent(event);
};

window.getNotificationPermission = () => {
  try {
    if (window.onAndroidApp()) {
      Android.getNotificationPermission();
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.getNotificationPermission.postMessage('');
    } else {
      window.setNotificationPermission(true);
    }
  } catch (error) {
    console.log('getNotificationPermission error', { error });
  }
};

window.setNotificationPermission = (permission) => {
  const event = new CustomEvent(EVENT_TYPE_NOTIFICATIONS_PERMISSIONS, { detail: { permission } });
  document.dispatchEvent(event);
};

window.getFCMToken = () => {
  try {
    if (window.onAndroidApp()) {
      Android.getFCMToken();
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.getFCMToken.postMessage('');
    } else {
      window.setFCMToken('');
    }
  } catch (error) {
    console.log('getFCMToken error', { error });
  }
};

window.setFCMToken = (token) => {
  const event = new CustomEvent(EVENT_TYPE_NOTIFICATIONS_TOKEN, { detail: { token } });
  document.dispatchEvent(event);
};

window.getCameraPermission = () => {
  try {
    if (window.onAndroidApp()) {
      Android.getCameraPermission();
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.getCameraPermission.postMessage('');
    } else {
      window.setCameraPermission(true);
    }
  } catch (error) {
    console.log('getCameraPermission error', { error });
  }
};

window.setCameraPermission = (permission) => {
  const event = new CustomEvent(EVENT_TYPE_CAMERA_PERMISSIONS, { detail: { permission } });
  document.dispatchEvent(event);
};

window.openFinsus = () => {
  try {
    if (window.onAndroidApp()) {
      Android.openFinsus();
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.openFinsus.postMessage('');
    }
  } catch (error) {
    console.log('openFinsus error', { error });
  }
};

window.backPressed = () => {
  const event = new CustomEvent(EVENT_TYPE_BACK_BUTTON);
  document.dispatchEvent(event);
};

window.downloadDocument = (url, filename) => {
  try {
    if (window.onAndroidApp()) {
      Android.downloadDocument(url, filename);
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.downloadDocument.postMessage({ url, filename });
    } else {
      window.open(fileURL);
    }
  } catch (error) {
    console.log('downloadDocument error', { error, url, filename });
  }
};

function blobToBase64(blob, callback) {
  var reader = new FileReader();
  reader.onload = function () {
    var dataUrl = reader.result;
    var base64 = dataUrl.split(',')[1];
    callback(base64);
  };
  reader.readAsDataURL(blob);
}

window.share = (title, message, type) => {
  try {
    if (window.onAndroidApp()) {
      blobToBase64(message, (base64) => {
        Android.share(title, base64, type);
      });
    } else if (window.onIOSApp()) {
      blobToBase64(message, (base64) => {
        window.webkit.messageHandlers.share.postMessage({ title, message: base64, type });
      });
    } else {
      const fileURL = URL.createObjectURL(message);
      window.open(fileURL);
    }
  } catch (error) {
    console.log('share error', { error, title, message, type });
  }
}

window.getPhotoLibraryPermission = () => {
  try {
    if (window.onAndroidApp()) {
      Android.getPhotoLibraryPermission();
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.getPhotoLibraryPermission.postMessage('');
    } else {
      window.setPhotoLibraryPermission(true);
    }
  } catch (error) {
    console.log('getPhotoLibraryPermission error', { error });
  }
};

window.setPhotoLibraryPermission = (permission) => {
  const event = new CustomEvent(EVENT_TYPE_LIBRARY_PERMISSIONS, { detail: { permission } });
  document.dispatchEvent(event);
};

window.openExternalLink = (url) => {
  try {
    if (window.onAndroidApp()) {
      Android.openExternalLink(url);
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.openExternalLink.postMessage({ url });
    } else {
      window.open(url, '_blank');
    }
  } catch (error) {
    console.log('openExternalLink error', { error, url });
  }
};

window.openDobleAuth = (url) => {
  try {
    if (window.onAndroidApp()) {
      Android.openDobleAuth(url);
    } else if (window.onIOSApp()) {
      window.webkit.messageHandlers.openDobleAuth.postMessage({ url });
    } else {
      window.open(url, '_blank');
    }
  } catch (error) {
    window.open(url, '_blank');
    console.log('openDobleAuth error', { error, url });
  }
}
