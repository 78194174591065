import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useLiterals from 'utils/hooks/useLiterals';
import Button from 'components/UI/Button';
import Modal from 'components/Modal';
import { createMultipleReference, createReference } from 'modules/bancomer/actions';
import { ReactComponent as CardIcon } from 'assets/icons/card.svg';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import { API_BASE_URL } from 'constants/apiConf';
import { sendSentryLog } from 'utils/sendSentryLog';
import { CONTRACT, SECTION } from 'constants/global';
import { ROUTE_PATH } from 'routes';
import BBVAForm from './components/BBVAForm';
import './styles.scss';

const FORM = {
  mp_account: window.BANCOMER.MP_ACCOUNT,
  mp_product: '1',
  mp_order: 'B00001',
  mp_reference: '',
  mp_node: '2',
  mp_concept: '3',
  mp_currency: '1',
  mp_amount: '0',
  mp_urlsuccess: `${API_BASE_URL}/bbva/payments/success`,
  mp_urlfailure: `${API_BASE_URL}/bbva/payments/failure`,
  mp_signature: '',
};

const BBVA = ({
  id,
  type,
  payment,
  orders,
  amount,
  isMultiple,
  onClose,
}) => {
  const literals = useLiterals('contractPay');
  const [formValue, setFormValue] = useState(FORM);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const formRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldSubmit) {
      const browser = !!window?.mlsQueryParams?.bancomerExternalRedirect;
      sendSentryLog({
        formValue,
        mode: browser ? 'external' : 'internal',
      }, 'BBVA Form Value');
      if (browser) {
        const paymentParams = btoa(JSON.stringify(formValue));
        window.openDobleAuth(`${document.location.origin}${ROUTE_PATH.setBBVARedirect()}?payment=${paymentParams}`);
        setTimeout(() => {
          navigate(ROUTE_PATH.PAYMENTS);
        }, 500);
      } else {
        formRef.current.submit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSubmit]);

  const handleConfirmModal = () => {
    setLoading(true);
    const callback = (response) => {
      setLoading(false);
      if (response) {
        setFormValue({
          ...formValue,
          mp_amount: response.amount,
          mp_reference: response.id,
          mp_order: response.external_order,
          mp_signature: response.signature,
        });
        setShouldSubmit(true);
      } else {
        setError(true);
      }
    };

    if (isMultiple) {
      createMultipleReference(amount, orders).then(callback);
    } else {
      createReference({
        contractType: type,
        contract_id: type === CONTRACT ? id : null,
        section_id: type === SECTION ? id : null,
        product: formValue.mp_product,
        amount: amount || payment?.amount,
        monto: payment?.amount,
        currency: formValue.mp_currency,
        paymentKey: payment?.paymentKey || null,
      }).then(callback);
    }
  };

  return (
    <>
      <Modal
        title={literals.bancomer.confirmTitle}
        onCloseModal={onClose}
        onConfirm={handleConfirmModal}
      >
        <div className='text-center'>
          <CardIcon className='my-3' />
          <BBVAForm form={formValue} ref={formRef} />
          <div className='text-gray mb-4'>{literals.bancomer.confirmDescription}</div>
          <Button
            disabled={loading}
            onClick={onClose}
            type='secondary'
            text={literals.common.cancel}
            className='mb-3'
          />
          <Button
            loading={loading}
            onClick={handleConfirmModal}
            type='primary'
            text={literals.common.continue}
          />
        </div>
      </Modal>
      {error && (
        <ErrorModal
          title={literals.bancomer.failureReferenceTitle}
          description={literals.bancomer.failureReferenceDescription}
          onClose={() => setError(false)}
        />
      )}
    </>
  );
};

BBVA.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  payment: PropTypes.object,
  orders: PropTypes.array,
  amount: PropTypes.number.isRequired,
  isMultiple: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

BBVA.defaultProps = {
  id: '',
  type: '',
  payment: null,
  orders: [],
  isMultiple: false,
};

export default BBVA;
