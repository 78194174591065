import BBVAForm from 'components/BBVA/components/BBVAForm';
import InlineError from 'components/UI/InlineError';
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useLiterals from 'utils/hooks/useLiterals';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTE_PATH } from 'routes';

const STEPS = {
  INIT: 'init',
  START: 'start',
  IN_PROGRESS: 'in-progress',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

const getQueryParamsObject = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(searchParams || {});
};

const PaymentRedirect = () => {
  const literals = useLiterals('payments');
  const [form, setForm] = useState({});
  const [error, setError] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const [payment, setPayment] = useState({});
  const [response, setResponse] = useState(false);
  const ref = useRef(null);
  const navigate = useNavigate();
  const { step } = useParams();

  const queryParams = useMemo(() => {
    return getQueryParamsObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /*
     * Bancomer a la hora de regresar hace un history -2,
     * por lo que necesitamos agregar un valor mas al history para que pueda regresar, y para que
     * no se quede en un loop infinito la primera vez que llega con los parametros se limpia la query
     */
    switch (step) {
      case STEPS.START:
        if (payment?.mp_signature) {
          navigate(ROUTE_PATH.setBBVARedirect(STEPS.IN_PROGRESS));
        } else {
          setRedirected(true);
        }
        break;
      case STEPS.IN_PROGRESS:
        if (payment?.mp_signature) {
          setForm(payment);
        } else {
          setRedirected(true);
        }
        break;
      case STEPS.SUCCESS:
      case STEPS.FAILURE:
        setResponse(true);
        break;
      default: {
        let success = false;
        if (queryParams.payment) {
          try {
            const newPayment = JSON.parse(atob(queryParams.payment));
            if (newPayment?.mp_signature) {
              setPayment(newPayment);
              success = true;
              navigate(ROUTE_PATH.setBBVARedirect(STEPS.START), { replace: true });
            }
          } catch (e) {
            setError(true);
          }
        }
        if (!success) {
          setError(true);
        }
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (ref.current && form?.mp_signature) {
      ref.current.submit();
    }
  }, [ref, form]);

  return (
    <div>
      <BBVAForm form={form} ref={ref} />
      {!redirected && !error && !response && (
        <p className='text-center'>
          {literals.loadingRedirect}
        </p>
      )}
      {redirected && (
        <p className='text-center'>
          {literals.closeWindow}
        </p>
      )}
      {error && (
        <InlineError error={literals.redirectError} />
      )}
      {response && (
        <div>
          <p className='font-bold'>Log response:</p>
          {Object.entries(getQueryParamsObject()).map(([key, value]) => (
            <div key={key}>
              <p className='mb-1'>{`- ${key}:`}</p>
              <p className='mb-3'>{value}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PaymentRedirect;
